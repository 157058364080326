import React from 'react';
import PropTypes from 'prop-types';

const colors = [
  {
    mainColor: 'cyan',
    ringColor: 'ring-cyan-500',
    backgroundColor: 'bg-cyan-600',
    backgroundColorHover: 'bg-cyan-700',
    borderColor: 'border-transparent',
    textColor: 'text-white',
  },
  {
    mainColor: 'white',
    ringColor: 'ring-gray-300',
    backgroundColor: 'bg-white',
    backgroundColorHover: 'bg-gray-50',
    borderColor: 'border-gray-300',
    textColor: 'text-blue-gray-900',
  },
  {
    mainColor: 'red',
    ringColor: 'ring-red-500',
    backgroundColor: 'bg-red-600',
    backgroundColorHover: 'bg-red-700',
    borderColor: 'border-transparent',
    textColor: 'text-white',
  },
];

const sizes = [
  {
    size: 'medium',
    padding: 'py-2 px-4',
    textSize: 'text-sm',
  },
  {
    size: 'large',
    padding: 'py-3 px-6',
    textSize: 'text-base',
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Button({
  isLoading,
  label,
  mainColor = 'cyan',
  size = 'medium',
  handleOnClick,
  disabled,
  fullWidth = false,
  ...props
}) {
  const selectedColor = colors.find((item) => item.mainColor === mainColor);
  const selectedSize = sizes.find((item) => item.size === size);

  return (
    <button
      disabled={disabled}
      onClick={!isLoading ? handleOnClick : undefined}
      {...props}
      className={classNames(
        fullWidth ? 'w-full' : '',
        disabled
          ? 'border-transparent text-gray-300 bg-gray-100 cursor-default'
          : `${selectedColor.borderColor} ${selectedColor.textColor} ${selectedColor.backgroundColor} hover:${selectedColor.backgroundColorHover}`,
        `flex items-center justify-center ${selectedSize.padding} border rounded-md shadow-sm ${selectedSize.textSize} font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:${selectedColor.ringColor}`
      )}
    >
      {isLoading && (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
      <span>{label}</span>
    </button>
  );
}

Button.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string.isRequired,
  mainColor: PropTypes.oneOf(['cyan', 'white', 'red']),
  size: PropTypes.oneOf(['medium', 'large']),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  handleOnClick: PropTypes.func,
};

export default Button;
