import React from "react";
import PropTypes from "prop-types";

import AppLayout from "@layouts/app";

const layouts = {
  app: AppLayout,
};

export const ProtectedRoute = ({ needAuth, roles, children }) => {
  // const { isAuth, hasRoles } = useAuth();

  // if (needAuth && !isAuth()) {
  //   return <Navigate to="/" />;
  // }
  //
  // if (needAuth && !hasRoles(roles)) {
  //   return <Navigate to="/not-enough-permissions" />;
  // }

  return children;
};

ProtectedRoute.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  needAuth: PropTypes.bool.isRequired,
  children: PropTypes.any,
};

export const LayoutRoute = ({ layout, children }) => {
  const LayoutComponent = layout ? layouts[layout] : null;

  if (LayoutComponent) {
    return <LayoutComponent>{children}</LayoutComponent>;
  }

  return children;
};

LayoutRoute.propTypes = {
  layout: PropTypes.string,
  children: PropTypes.any,
};
