import React, { useMemo, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "@atoms/Button";
import Typography from "@atoms/Typography";
import { toastSuccess } from "@atoms/Toasts";
import InputText from "@atoms/InputText";
import {
  getRandomCurrency,
  getRandomItemBrand,
  getRandomItemCategory,
  getRandomItemId,
  getRandomItemName,
  getRandomItemVariant,
  getRandomTransactionId,
  getRandomValues,
} from "@helpers/random";

const Purchase = () => {
  const [randomValues, setRandomValues] = useState({});

  const Schema = useMemo(() => {
    return Yup.object().shape({
      value: Yup.number().required("value required"),
      currency: Yup.string().required("currency required"),
      transactionId: Yup.string().required("transaction Id required"),
      itemId: Yup.string().required("item Id required"),
      itemName: Yup.string().required("item name required"),
      price: Yup.number().required("price required"),
      quantity: Yup.number().required("quantity required"),
    });
  }, []);

  const generateRandomValues = () => {
    const randomValues = getRandomValues();
    setRandomValues({
      value: randomValues.value,
      currency: getRandomCurrency(),
      transactionId: getRandomTransactionId(),
      itemId: getRandomItemId(),
      itemName: getRandomItemName(),
      itemBrand: getRandomItemBrand(),
      itemVariant: getRandomItemVariant(),
      itemCategory: getRandomItemCategory(),
      price: randomValues.price,
      quantity: randomValues.quantity,
    });
  };

  return (
    <div className="mx-8 mt-5">
      <div className="w-full flex justify-between">
        <Typography variant="text-2xl" weight="bold">
          Purchase
        </Typography>
        <Button
          label="Generate random values"
          handleOnClick={() => generateRandomValues()}
        />
      </div>
      <div className="mt-8">
        <Formik
          enableReinitialize
          initialValues={{
            value: randomValues.value || undefined,
            currency: randomValues.currency || undefined,
            transactionId: randomValues.transactionId || undefined,
            itemId: randomValues.itemId || undefined,
            itemName: randomValues.itemName || undefined,
            coupon: undefined,
            discount: undefined,
            itemBrand: randomValues.itemBrand || undefined,
            itemCategory: randomValues.itemCategory || undefined,
            itemVariant: randomValues.itemVariant || undefined,
            price: randomValues.price || undefined,
            quantity: randomValues.quantity || undefined,
          }}
          validationSchema={Schema}
          onSubmit={async (values, { setSubmitting }) => {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                ...(values.currency && { currency: values.currency }),
                ...(values.value && { value: values.value }),
                ...(values.transactionId && {
                  transaction_id: values.transactionId,
                }),
                items: [
                  {
                    ...(values.itemId && { item_id: values.itemId }),
                    ...(values.itemName && { item_name: values.itemName }),
                    ...(values.coupon && { coupon: values.coupon }),
                    ...(values.discount && { discount: values.discount }),
                    ...(values.itemBrand && { item_brand: values.itemBrand }),
                    ...(values.itemCategory && {
                      item_category: values.itemCategory,
                    }),
                    ...(values.itemVariant && {
                      item_variant: values.itemVariant,
                    }),
                    ...(values.price && { price: values.price }),
                    ...(values.quantity && { quantity: values.quantity }),
                  },
                ],
              },
            });
            setSubmitting(false);
            toastSuccess("purchase pushed successfully");
          }}
        >
          {({ values, errors, touched, handleChange, isSubmitting }) => (
            <div>
              <Form className="">
                <div className="gap-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  <InputText
                    id="value"
                    type="text"
                    errorMessage={touched?.value && errors?.value}
                    label="value"
                    placeholder="value"
                    handleOnChange={handleChange}
                    value={values.value}
                  />
                  <InputText
                    id="currency"
                    type="text"
                    errorMessage={touched?.currency && errors?.currency}
                    label="currency"
                    placeholder="currency"
                    handleOnChange={handleChange}
                    value={values.currency}
                  />
                  <InputText
                    id="transactionId"
                    type="text"
                    errorMessage={
                      touched?.transactionId && errors?.transactionId
                    }
                    label="transaction Id"
                    placeholder="transaction Id"
                    handleOnChange={handleChange}
                    value={values.transactionId}
                  />
                </div>
                <div className="rounded-lg bg-gray-200 my-5 p-5 gap-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  <InputText
                    id="itemId"
                    type="text"
                    errorMessage={touched?.itemId && errors?.itemId}
                    label="item Id"
                    placeholder="item Id"
                    handleOnChange={handleChange}
                    value={values.itemId}
                  />
                  <InputText
                    id="itemName"
                    type="text"
                    errorMessage={touched?.itemName && errors?.itemName}
                    label="item name"
                    placeholder="item name"
                    handleOnChange={handleChange}
                    value={values.itemName}
                  />
                  <InputText
                    id="coupon"
                    type="text"
                    errorMessage={touched?.coupon && errors?.coupon}
                    label="coupon"
                    placeholder="coupon"
                    handleOnChange={handleChange}
                    value={values.coupon}
                  />
                  <InputText
                    id="discount"
                    type="text"
                    errorMessage={touched?.discount && errors?.discount}
                    label="discount"
                    placeholder="discount"
                    handleOnChange={handleChange}
                    value={values.discount}
                  />
                  <InputText
                    id="itemBrand"
                    type="text"
                    errorMessage={touched?.itemBrand && errors?.itemBrand}
                    label="item brand"
                    placeholder="item brand"
                    handleOnChange={handleChange}
                    value={values.itemBrand}
                  />
                  <InputText
                    id="itemCategory"
                    type="text"
                    errorMessage={touched?.itemCategory && errors?.itemCategory}
                    label="item category"
                    placeholder="item category"
                    handleOnChange={handleChange}
                    value={values.itemCategory}
                  />
                  <InputText
                    id="itemVariant"
                    type="text"
                    errorMessage={touched?.itemVariant && errors?.itemVariant}
                    label="item variant"
                    placeholder="item variant"
                    handleOnChange={handleChange}
                    value={values.itemVariant}
                  />
                  <InputText
                    id="price"
                    type="text"
                    errorMessage={touched?.price && errors?.price}
                    label="Price"
                    placeholder="Price"
                    handleOnChange={handleChange}
                    value={values.price}
                  />
                  <InputText
                    id="quantity"
                    type="text"
                    errorMessage={touched?.quantity && errors?.quantity}
                    label="Quantity"
                    placeholder="Quantity"
                    handleOnChange={handleChange}
                    value={values.quantity}
                  />
                </div>

                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  label="Push Purchase"
                />
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Purchase;
