import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

function Typography({
  children,
  variant,
  align = 'left',
  color = 'dark',
  weight = 'normal',
  extraClasses = '',
}) {
  const tailwindColor = useMemo(
    () =>
      ({
        dark: 'text-gray-900',
        light: 'text-gray-600',
        'extra-light': 'text-gray-400',
        green: 'text-green-600',
        cyan: 'text-cyan-600',
        red: 'text-red-600',
        white: 'text-white',
      }[color]),
    [color]
  );

  if (variant === 'h1') {
    return (
      <h1
        className={`text-5xl text-gray-900 font-semibold text-${align} ${tailwindColor} ${extraClasses}`}
      >
        {children}
      </h1>
    );
  }

  if (variant === 'h2') {
    return (
      <h2
        className={`text-3xl text-${align} leading-8 font-extrabold tracking-tight ${tailwindColor} ${extraClasses}`}
      >
        {children}
      </h2>
    );
  }

  if (variant === 'h3') {
    return (
      <h3
        className={`text-2xl text-${align} font-extrabold ${tailwindColor} tracking-tight sm:text-3xl ${extraClasses}`}
      >
        {children}
      </h3>
    );
  }
  if (variant === 'h4') {
    return (
      <h4
        className={`text-lg text-${align} font-medium ${tailwindColor} ${extraClasses}`}
      >
        {children}
      </h4>
    );
  }

  if (variant === 'text-xs') {
    return (
      <p
        className={`text-xs font-${weight} text-${align} ${tailwindColor} ${extraClasses}`}
      >
        {children}
      </p>
    );
  }

  if (variant === 'text-sm') {
    return (
      <p
        className={`text-sm font-${weight} text-${align} ${tailwindColor} ${extraClasses}`}
      >
        {children}
      </p>
    );
  }

  if (variant === 'text-lg') {
    return (
      <p
        className={`text-lg font-${weight} text-${align} ${tailwindColor} ${extraClasses}`}
      >
        {children}
      </p>
    );
  }

  if (variant === 'text-xl') {
    return (
      <p
        className={`text-xl font-${weight} text-${align} ${tailwindColor} ${extraClasses}`}
      >
        {children}
      </p>
    );
  }

  if (variant === 'text-2xl') {
    return (
      <p
        className={`text-2xl font-${weight} text-${align} ${tailwindColor} ${extraClasses}`}
      >
        {children}
      </p>
    );
  }

  if (variant === 'text-3xl') {
    return (
      <p
        className={`text-3xl font-${weight} text-${align} ${tailwindColor} ${extraClasses}`}
      >
        {children}
      </p>
    );
  }

  // Default "body" style
  return (
    <p
      className={`font-${weight} text-${align} ${tailwindColor} ${extraClasses}`}
    >
      {children}
    </p>
  );
}

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    'extra-light',
    'light',
    'dark',
    'green',
    'cyan',
    'white',
  ]),
  weight: PropTypes.oneOf(['normal', 'medium', 'semibold', 'bold']),
  variant: PropTypes.oneOf([
    'body',
    'h1',
    'h2',
    'h3',
    'h4',
    'text-xs',
    'text-sm',
    'text-md',
    'text-lg',
    'text-xl',
    'text-2xl',
    'text-3xl',
    'p',
  ]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  extraClasses: PropTypes.string,
};

export default Typography;
