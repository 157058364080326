import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@helpers/style';
import MenuIcon from '@atoms/MenuIcon';
import { Link } from 'react-router-dom';

function MenuItem({ item }) {
  if (item.enabled) {
    return (
      <Link
        to={item.href}
        className={classNames(
          item.current
            ? 'bg-gray-200 text-gray-900'
            : 'text-gray-700 hover:text-gray-900 hover:bg-gray-100',
          'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
        )}
      >
        <MenuIcon
          icon={item.icon}
          className={classNames(
            item.current
              ? 'text-gray-500'
              : 'text-gray-400 group-hover:text-gray-500',
            'mr-3 flex-shrink-0 h-6 w-6'
          )}
        />
        {item.name}
      </Link>
    );
  } else {
    return (
      <div className="group flex text-gray-300 items-center px-2 py-2 text-sm font-medium rounded-md">
        <MenuIcon
          icon={item.icon}
          className="text-gray-300 mr-3 flex-shrink-0 h-6 w-6"
        />
        {item.name}
      </div>
    );
  }
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string,
    icon: PropTypes.string.isRequired,
    current: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
  }).isRequired,
};

export default MenuItem;
