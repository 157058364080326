import React from 'react';
import PropTypes from 'prop-types';

function Logo({ color = 'cyan', height }) {
  return (
    <div className={'flex items-center'}>
      <img
        alt="Addingwell logo"
        src={`/images/addingwell-${color}.svg`}
        style={{ height: height, width: height * 4.7 }}
      />
    </div>
  );
}

Logo.propTypes = {
  color: PropTypes.oneOf(['cyan', 'white']),
  height: PropTypes.number.isRequired,
};

export default Logo;
