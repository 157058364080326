import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { MyRouter } from "./routes";
import "./App.css";
// import { Formik, Form } from "formik";
// import * as Yup from "yup";
// import InputText from "./components/atoms/InputText";
// import Button from "./components/atoms/Button";

function App() {
  // gtag('event', 'purchase', {
  //   'transaction_id': 'transaction_id_value',
  //   'currency': 'EUR',
  //   'coupon': 'coupon_value',
  //   'discount': 20,
  //   'value': 100,
  //   'items': [
  //     {
  //       'item_id': 'item_id_value',
  //       'price': 50,
  //       'quantity': 2,
  //       'discount': 20
  //     }
  //   ]
  // });

  // const Schema = useMemo(() => {
  //   return Yup.object().shape({
  //     transactionId: Yup.string().required("transactionId required"),
  //     value: Yup.string().required("value required"),
  //     itemId: Yup.string().required("itemId required"),
  //     itemPrice: Yup.string().required("itemPrice required"),
  //     itemQuantity: Yup.string().required("itemQuantity required"),
  //   });
  // }, []);

  return (
    <div className="App">
      <Toaster position="top-right" />

      <Router>
        <MyRouter />
      </Router>
      {/*<Formik*/}
      {/*  initialValues={{*/}
      {/*    transactionId: "",*/}
      {/*    coupon: "",*/}
      {/*    discount: "",*/}
      {/*    value: "",*/}
      {/*    itemId: "",*/}
      {/*    itemPrice: "",*/}
      {/*    itemQuantity: "",*/}
      {/*    itemDiscount: "",*/}
      {/*  }}*/}
      {/*  validationSchema={Schema}*/}
      {/*  onSubmit={async (values, { setSubmitting }) => {*/}
      {/*    // window.gtag("event", "purchase", {*/}
      {/*    //   transaction_id: values.transactionId,*/}
      {/*    //   currency: "EUR",*/}
      {/*    //   coupon: values.coupon || null,*/}
      {/*    //   discount: values.discount || null,*/}
      {/*    //   value: values.value,*/}
      {/*    //   items: [*/}
      {/*    //     {*/}
      {/*    //       item_id: values.itemId,*/}
      {/*    //       price: values.itemPrice,*/}
      {/*    //       quantity: values.itemQuantity,*/}
      {/*    //       discount: values.itemDiscount || null,*/}
      {/*    //     },*/}
      {/*    //   ],*/}
      {/*    // });*/}
      {/*    window.dataLayer.push({*/}
      {/*      event: "purchase",*/}
      {/*      transaction_id: values.transactionId,*/}
      {/*      currency: "EUR",*/}
      {/*      coupon: values.coupon || null,*/}
      {/*      discount: values.discount || null,*/}
      {/*      value: values.value,*/}
      {/*      items: [*/}
      {/*        {*/}
      {/*          item_id: values.itemId,*/}
      {/*          price: values.itemPrice,*/}
      {/*          quantity: values.itemQuantity,*/}
      {/*          discount: values.itemDiscount || null,*/}
      {/*        },*/}
      {/*      ],*/}
      {/*    });*/}
      {/*    setSubmitting(false);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {({ values, errors, touched, handleChange, isSubmitting }) => (*/}
      {/*    <Form className="space-y-6">*/}
      {/*      <InputText*/}
      {/*        id="transactionId"*/}
      {/*        type="text"*/}
      {/*        errorMessage={touched?.transactionId && errors?.transactionId}*/}
      {/*        label="transactionId"*/}
      {/*        placeholder="transactionId"*/}
      {/*        handleOnChange={handleChange}*/}
      {/*        value={values.transactionId}*/}
      {/*      />*/}

      {/*      <InputText*/}
      {/*        id="coupon"*/}
      {/*        type="text"*/}
      {/*        errorMessage={touched?.coupon && errors?.coupon}*/}
      {/*        label="coupon"*/}
      {/*        placeholder="coupon"*/}
      {/*        handleOnChange={handleChange}*/}
      {/*        value={values.coupon}*/}
      {/*      />*/}

      {/*      <InputText*/}
      {/*        id="discount"*/}
      {/*        type="text"*/}
      {/*        errorMessage={touched?.discount && errors?.discount}*/}
      {/*        label="discount"*/}
      {/*        placeholder="discount"*/}
      {/*        handleOnChange={handleChange}*/}
      {/*        value={values.discount}*/}
      {/*      />*/}

      {/*      <InputText*/}
      {/*        id="value"*/}
      {/*        type="text"*/}
      {/*        errorMessage={touched?.value && errors?.value}*/}
      {/*        label="value"*/}
      {/*        placeholder="value"*/}
      {/*        handleOnChange={handleChange}*/}
      {/*        value={values.value}*/}
      {/*      />*/}

      {/*      <InputText*/}
      {/*        id="itemId"*/}
      {/*        type="text"*/}
      {/*        errorMessage={touched?.itemId && errors?.itemId}*/}
      {/*        label="itemId"*/}
      {/*        placeholder="itemId"*/}
      {/*        handleOnChange={handleChange}*/}
      {/*        value={values.itemId}*/}
      {/*      />*/}

      {/*      <InputText*/}
      {/*        id="itemPrice"*/}
      {/*        type="text"*/}
      {/*        errorMessage={touched?.itemPrice && errors?.itemPrice}*/}
      {/*        label="itemPrice"*/}
      {/*        placeholder="itemPrice"*/}
      {/*        handleOnChange={handleChange}*/}
      {/*        value={values.itemPrice}*/}
      {/*      />*/}

      {/*      <InputText*/}
      {/*        id="itemQuantity"*/}
      {/*        type="text"*/}
      {/*        errorMessage={touched?.itemQuantity && errors?.itemQuantity}*/}
      {/*        label="itemQuantity"*/}
      {/*        placeholder="itemQuantity"*/}
      {/*        handleOnChange={handleChange}*/}
      {/*        value={values.itemQuantity}*/}
      {/*      />*/}

      {/*      <InputText*/}
      {/*        id="itemDiscount"*/}
      {/*        type="text"*/}
      {/*        errorMessage={touched?.itemDiscount && errors?.itemDiscount}*/}
      {/*        label="itemDiscount"*/}
      {/*        placeholder="itemDiscount"*/}
      {/*        handleOnChange={handleChange}*/}
      {/*        value={values.itemDiscount}*/}
      {/*      />*/}

      {/*      <Button*/}
      {/*        type="submit"*/}
      {/*        fullWidth={true}*/}
      {/*        isLoading={isSubmitting}*/}
      {/*        label="button"*/}
      {/*      />*/}
      {/*    </Form>*/}
      {/*  )}*/}
      {/*</Formik>*/}
    </div>
  );
}

export default App;
