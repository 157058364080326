/*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/
Array.prototype.random = function () {
  return this[Math.floor(Math.random() * this.length)];
};

export const getRandomItemId = () => {
  return generateId(10);
};

export const getRandomItemName = () => {
  return listItemName.random();
};

export const getRandomItemBrand = () => {
  return listItemBrand.random();
};

export const getRandomItemCategory = () => {
  return listItemCategory.random();
};

export const getRandomItemVariant = () => {
  return listItemVariant.random();
};

export const getRandomCurrency = () => {
  return listCurrency.random();
};

export const getRandomTransactionId = () => {
  return generateId(12);
};

export const getRandomValues = () => {
  const randomPrice = Math.floor(Math.random() * 50) + 1;
  const randomQuantity = Math.floor(Math.random() * 5) + 1;
  return {
    price: randomPrice,
    quantity: randomQuantity,
    value: randomPrice * randomQuantity,
  };
};

const generateId = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const listCurrency = ["GBP", "USD", "EUR"];

const listItemName = [
  "Alteza",
  "Alvario",
  "CraftLabel",
  "Darsh",
  "Dawn Essence",
  "EpicLabel",
  "Honey Relish",
  "Revive Pomade",
  "CloudGen",
  "CodeMousse",
  "Deductly",
  "GameEight",
  "GreenBay",
  "MotionIn",
  "Pluckey",
  "Rentoor",
  "Savannite",
  "Accel Minds",
  "Agri Mojo",
  "App Mojo",
  "Flutter Ways",
  "Inseed",
  "Novalash",
  "Velvet Styles",
  "Zenith",
  "HawkEye",
];

const listItemBrand = [
  "Brand Scope",
  "The Brand Project",
  "Brand Vibe",
  "Brand House",
  "Brandium",
  "Brand Atlas",
  "Brand Layer",
  "Brand Charm",
  "Brandal",
  "Brand Glow",
  "Brand Design",
  "Brand Stock",
  "Brand Flow",
  "Brand Tags",
  "Brand Start",
  "Brandied",
];

const listItemCategory = [
  "Category Space",
  "Category Maker",
  "Category Blend",
  "Category Storm",
  "Category Store",
];

const listItemVariant = [
  "Green",
  "Blue",
  "Red",
  "Orange",
  "Grey",
  "Black",
  "White",
];
