import React from "react";
import { Formik, Form } from "formik";
import Button from "@atoms/Button";
import Typography from "@atoms/Typography";
import { toastSuccess } from "@atoms/Toasts";

const PageView = () => {
  return (
    <div className="ml-8 mt-5">
      <Typography variant="text-2xl" weight="bold">
        Page View
      </Typography>
      <div className="mt-8">
        <Formik
          initialValues={{}}
          onSubmit={async (values, { setSubmitting }) => {
            window.dataLayer.push({
              event: "page_view",
            });
            setSubmitting(false);
            toastSuccess("page_view pushed successfully");
          }}
        >
          {({ values, errors, touched, handleChange, isSubmitting }) => (
            <Form className="space-y-6">
              <Button
                type="submit"
                isLoading={isSubmitting}
                label="Push Page View"
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PageView;
