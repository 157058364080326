import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import PageNotFound from "./pages/PageNotFound";
import { LayoutRoute, ProtectedRoute } from "./components/MyRoute/MyRoute";
import Home from "./pages/Home";
import PageView from "./pages/Tracking/PageView";
import ViewItem from "./pages/Tracking/ViewItem";
import AddToCart from "./pages/Tracking/AddToCart";
import Purchase from "./pages/Tracking/Purchase";

const routes = [
  {
    name: "home",
    path: "/",
    component: <Home />,
    roles: [],
    needAuth: false,
    layout: "app",
  },
  {
    path: "/tracking/:gtmId",
    routes: [
      {
        name: "pageView",
        path: "/page-view",
        component: <PageView />,
        needAuth: false,
        layout: "app",
      },
      {
        name: "viewItem",
        path: "/view-item",
        component: <ViewItem />,
        needAuth: false,
        layout: "app",
      },
      {
        name: "addToCart",
        path: "/add-to-cart",
        component: <AddToCart />,
        needAuth: false,
        layout: "app",
      },
      {
        name: "purchase",
        path: "/purchase",
        component: <Purchase />,
        needAuth: false,
        layout: "app",
      },
    ],
  },
  {
    name: "noMatch",
    path: "*",
    component: <PageNotFound />,
    roles: [],
    needAuth: false,
  },
];

const compile = (parentRoute, subRoutes) => {
  return subRoutes
    .map((subRoute) => {
      const newRoute = {
        name: subRoute.name,
        path: parentRoute.path + subRoute.path,
        component: subRoute.component,
        redirect: subRoute.redirect,
        roles: (parentRoute.roles || []).concat(subRoute.roles || []),
        needAuth: subRoute.needAuth,
        layout: subRoute.layout,
      };
      return subRoute.routes
        ? [...compile(newRoute, subRoute.routes)]
        : newRoute;
    })
    .reduce((a, b) => a.concat(b), []);
};

const getRoutes = () => {
  const parentRoute = {
    name: "",
    path: "",
  };
  return compile(parentRoute, routes);
};

/**
 * @param {string} name
 * @param {any} params
 * @return {string}
 */
const getPath = (name, params = null) => {
  const routeFound = getRoutes().find((route) => route.name === name);
  let path = routeFound ? routeFound.path : null;
  if (path && params) {
    Object.entries(params).forEach(([key, value]) => {
      path = path ? path.replace(`:${key}`, value) : "";
    });
  }
  return path;
};

/**
 * @param {string} workspaceUuid
 * @param {string|undefined} routeName
 * @param {string|undefined} role
 * @param {any} params
 * @return {string}
 */
const getRedirectPath = (workspaceUuid, routeName, role, params = null) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set("workspaceUuid", workspaceUuid);

  if (routeName) {
    urlSearchParams.set("route", routeName);
  }

  if (role) {
    urlSearchParams.set("role", role);
  }

  return getPath("admin_redirect") + `?${urlSearchParams}`;
};

const MyRouter = () => {
  // usePageTracking();
  return (
    <Routes>
      {getRoutes().map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={true}
            element={
              <ProtectedRoute roles={route.roles} needAuth={route.needAuth}>
                <LayoutRoute layout={route.layout}>
                  {route.redirect ? (
                    <Navigate to={getPath(route.redirect)} />
                  ) : (
                    route.component
                  )}
                </LayoutRoute>
              </ProtectedRoute>
            }
          />
        );
        // return <MyRoute exact {...route} key={index} />;
      })}
    </Routes>
  );
};

export { getRoutes, getPath, MyRouter, getRedirectPath };
