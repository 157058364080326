import React, { useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Logo from "@atoms/Logo";
import MenuItem from "@molecules/MenuItem";
import Button from "@atoms/Button";
import InputText from "@atoms/InputText";
import { Helmet } from "react-helmet";

function DashboardLayout({ children }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { gtmId } = useParams();

  const Schema = useMemo(() => {
    return Yup.object().shape({
      gtmId: Yup.string().required("GTM ID required"),
    });
  }, []);

  const mainNavigation = useMemo(() => {
    return [
      {
        name: "Page View",
        href: `/tracking/${gtmId}/page-view`,
        icon: "",
        current: pathname.includes(`/tracking/${gtmId}/page-view`),
        enabled: true,
      },
      {
        name: "View Item",
        href: `/tracking/${gtmId}/view-item`,
        icon: "",
        current: pathname.includes(`/tracking/${gtmId}/view-item`),
        enabled: true,
      },
      {
        name: "Add To Cart",
        href: `/tracking/${gtmId}/add-to-cart`,
        icon: "",
        current: pathname.includes(`/tracking/${gtmId}/add-to-cart`),
        enabled: true,
      },
      {
        name: "Purchase",
        href: `/tracking/${gtmId}/purchase`,
        icon: "",
        current: pathname.includes(`/tracking/${gtmId}/purchase`),
        enabled: true,
      },
    ];
  }, [gtmId, pathname]);

  return (
    <div className="h-screen bg-gray-100 flex overflow-hidden">
      <Helmet>
        {gtmId && (
          <script async="true">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');`}</script>
        )}
      </Helmet>
      <div className="hidden justify-between border-r bg-white md:flex md:flex-shrink-0 flex-col">
        <div className="flex flex-col w-64 flex-grow">
          <div className="flex flex-col flex-grow pt-5 overflow-y-auto">
            <div className="px-4 h-12 mr-auto">
              <Logo color="cyan" height={40} />
            </div>
            {mainNavigation && (
              <div className="border-t border-gray-200 pt-5 mt-3 h-0 flex-1 flex flex-col overflow-y-auto">
                <Formik
                  initialValues={{
                    gtmId,
                  }}
                  validationSchema={Schema}
                  onSubmit={async (values) => {
                    navigate(`/tracking/${values.gtmId}`);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    isSubmitting,
                  }) => (
                    <Form className="space-y-6">
                      <div className="flex gap-3 mx-3">
                        <InputText
                          id="gtmId"
                          errorMessage={touched?.gtmId && errors?.gtmId}
                          handleOnChange={handleChange}
                          type="text"
                          name="name"
                          value={values.gtmId}
                          placeholder="Your GTM ID"
                        />
                        <Button label="Load" type="submit" />
                      </div>
                    </Form>
                  )}
                </Formik>

                <div className="border-t border-gray-200 pt-3 mt-5 flex-1 flex flex-col justify-between">
                  <nav className="px-3 space-y-1">
                    {mainNavigation.map((item) => (
                      <MenuItem key={item.name} item={item} />
                    ))}
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex-1 flex flex-col">
        <div className="w-full max-w-7xl mx-auto md:px-8">
          <div className="relative z-10 flex-shrink-0 h-16 bg-gray-100 border-b border-gray-200 flex">
            <div className="flex-1 flex items-center justify-between px-4 md:px-0">
              <div className="flex-1 flex"></div>
              <div className="ml-3 flex items-center md:ml-3"></div>
            </div>
          </div>
        </div>
        <main className="flex-1 overflow-y-auto focus:outline-none">
          <div className="h-full">{children}</div>
        </main>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
