import React from 'react';
import PropTypes from 'prop-types';
import {
  HiOutlineClipboardList,
  HiOutlineHome,
  HiOutlineServer,
  HiOfficeBuilding,
  HiOutlineBriefcase,
} from 'react-icons/hi';
import {
  ClockIcon,
  GlobeAltIcon,
  TagIcon,
  RocketLaunchIcon,
  UserGroupIcon,
  LifebuoyIcon,
} from '@heroicons/react/24/outline';
import { AiOutlineFileUnknown } from 'react-icons/ai';
import { BiCookie } from 'react-icons/bi';

const MenuIcon = ({ icon, className }) => {
  switch (icon) {
    case 'HiOutlineServer':
      return <HiOutlineServer className={className} />;
    case 'TagIcon':
      return <TagIcon className={className} />;
    case 'HiOutlineClipboardList':
      return <HiOutlineClipboardList className={className} />;
    case 'HiOutlineHome':
      return <HiOutlineHome className={className} />;
    case 'GlobeAltIcon':
      return <GlobeAltIcon className={className} />;
    case 'OfficeBuildingIcon':
      return <HiOfficeBuilding className={className} />;
    case 'SupportIcon':
      return <LifebuoyIcon className={className} />;
    case 'ClockIcon':
      return <ClockIcon className={className} />;
    case 'RocketLaunchIcon':
      return <RocketLaunchIcon className={className} />;
    case 'BiCookie':
      return <BiCookie className={className} />;
    case 'HiOutlineBriefcase':
      return <HiOutlineBriefcase className={className} />;
    case 'UserGroupIcon':
      return <UserGroupIcon className={className} />;
    default:
      return <AiOutlineFileUnknown className={className} />;
  }
};

MenuIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default MenuIcon;
