import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";
import ReactTooltip from "react-tooltip";

import Typography from "./Typography";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function InputText({
  placeholder,
  id,
  type,
  maxLength = -1,
  isRequired = false,
  errorMessage,
  label,
  handleOnChange,
  hint,
  autoComplete,
  disabled,
  value,
  helper,
  ariaDescribedby,
}) {
  const mainColor = useMemo(
    () => (errorMessage ? "red" : "gray"),
    [errorMessage]
  );
  const focusColor = "cyan";

  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className="mb-1 block text-sm font-medium text-gray-700"
        >
          {label} {isRequired ? "*" : ""}
        </label>
      )}
      <div className="relative rounded-md shadow-sm">
        <input
          type={type || "text"}
          name={id}
          id={id}
          maxLength={maxLength}
          onChange={handleOnChange}
          value={value}
          className={classNames(
            helper || errorMessage ? "pr-10" : "pr-3",
            disabled
              ? `bg-gray-50 text-${mainColor}-300`
              : `text-${mainColor}-900`,
            `block w-full border-${mainColor}-300 placeholder-${mainColor}-300 focus:outline-none focus:ring-${focusColor}-500 focus:border-${focusColor}-500 text-sm rounded-md`
          )}
          placeholder={placeholder || ""}
          aria-invalid={!!errorMessage}
          disabled={disabled}
          autoComplete={autoComplete}
          aria-describedby={ariaDescribedby}
        />
        {errorMessage && !helper && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        {helper && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            <QuestionMarkCircleIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
              data-tip
              data-for={`help-${id}`}
            />
            <ReactTooltip
              id={`help-${id}`}
              type="dark"
              place="top"
              effect="solid"
            >
              {helper}
            </ReactTooltip>
          </div>
        )}
      </div>
      {errorMessage && (
        <p className="mt-2 text-sm text-red-600" id={id}>
          {errorMessage}
        </p>
      )}
      {hint && (
        <div className="mt-1">
          <Typography variant="text-xs">{hint}</Typography>
        </div>
      )}
    </div>
  );
}

InputText.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "email", "number", "password"]),
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number,
  label: PropTypes.string,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  handleOnChange: PropTypes.func.isRequired,
  hint: PropTypes.string,
  value: PropTypes.string.isRequired,
  helper: PropTypes.object,
  ariaDescribedby: PropTypes.string,
};

export default InputText;
